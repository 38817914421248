import React, { useState, useRef, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import Home from './pages/Home';
import Register from './pages/Register';
import List from './pages/List';

axiosInstance.interceptors.request.use(
    (config) => {
        console.log(`Request: ${config.method.toUpperCase()} ${config.url}`);
        console.log('Request Headers:', config.headers);
        console.log('Request Data:', config.data);
        return config;
    },
    (error) => {
        console.eror('Request Error:', error);
        return Promise.regect(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        console.log(`Response from: ${response.config.url}`);
        console.log('Status:', response.status);
        console.log('Response Data:', response.data);
        return response;
    },
    (error) => {
        if (error.response) {
            console.error(`Error Response from: ${error.response.config.url}`);
            console.error('Status:', error.response.status);
            console.error('Response Data:', error.response.data);
        } else {
            console.error('Request failed:', error.message);
        }
        return Promise.reject(error);
    }
);

function App() {
  return (
    <Router>
      <Routes>
        <Route 
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/list/:id" element={<List />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </Router>
  );
}

export default App;
