import React, { useState } from 'react';
import { shareShoppingList, searchUsers } from '../api/shoppingList';

const ShareListButton = ({ listId }) => {
    const [isSharing, setIsSharing] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const handleSearch = async (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value.trim() !== '') {
            try {
                const users = await searchUsers(e.target.value);
                setSearchResults(users);
            } catch (error) {
                console.error('Error searching users:', error);
            }
        } else {
            setSearchResults([]);
        }
    };

    const handleShare = async () => {
        if (!selectedUser) return;

        try {
            await shareShoppingList(listId, selectedUser.id);
            alert(`List shared with ${selectedUser.username}`);
            setIsSharing(false);
        } catch (error) {
            console.error('Error sharing list:', error);
            alert('Failed to share the list');
        }
    };

    return (
        <div className="p-4">
          {/* Share Button */}
          <button 
            onClick={() => setIsSharing(!isSharing)} 
            className="bg-blue-500 text-white rounded-md p-2 mb-4 hover:bg-blue-600 transition w-full"
          >
            Share List
          </button>
      
          {/* Search Bar and User List (Shown when Share button is clicked) */}
          {isSharing && (
            <div className="bg-white shadow-md rounded-lg p-4">
              <input
                type="text"
                placeholder="Search for a user..."
                value={searchTerm}
                onChange={handleSearch}
                className="border border-gray-300 rounded-md p-2 mb-4 w-full"
              />
              <ul className="list-disc pl-5 mb-4">
                {searchResults.map((user) => (
                  <li 
                    key={user.id} 
                    onClick={() => setSelectedUser(user)} 
                    className="cursor-pointer hover:bg-gray-100 p-2 rounded-md"
                  >
                    {user.username}
                  </li>
                ))}
              </ul>
      
              {/* Confirm Share Button */}
              {selectedUser && (
                <div className="mt-4">
                  <p className="text-gray-800">Selected User: {selectedUser.username}</p>
                  <button 
                    onClick={handleShare} 
                    className="bg-green-500 text-white rounded-md p-2 hover:bg-green-600 transition w-full"
                  >
                    Confirm Share
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
    );
};

export default ShareListButton;
