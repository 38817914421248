import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getListItems,
  getShoppingLists,
  addItemToList,
  deleteListItems,
  deleteShoppingList,
  getSharedLists,
  getListSharedUsers,
  removeUserSharing
} from '../api/shoppingList';
import ShareListButton from '../components/ShareListButton'

function List() {
  const { id } = useParams();
  const [listName, setListName] = useState('');
  const [items, setItems] = useState([]);
  const [item, setItem] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [sharedUsers, setSharedUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getListName();
    getItems();
    fetchSharedUsers();
  }, []);

  const getListName = async () => {
    const lists = await getShoppingLists();
    const sharedLists = await getSharedLists();
    const allLists = [...lists, ...sharedLists];
    const list = allLists.find((list) => list.id == id);
    setListName(list.name);
  };

  const getItems = async () => {
    const response = await getListItems(id);
    setItems(response);
  };

  const fetchSharedUsers = async () => {
    const users = await getListSharedUsers(id);
    setSharedUsers(users);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addItem();
    }
  };

  const addItem = async () => {
    if (item.trim() === '') return;
    const response = await addItemToList(id, item);
    setItems([...items, response]);
    setItem('');
  };

  const handleSelectItem = (itemId) => {
    if (selectedItems.includes(itemId)) {
      // If already selected, deselect it
      setSelectedItems(selectedItems.filter(id => id !== itemId));
    } else {
      // Select the item
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const handleDeleteSelectedItems = async () => {
    try {
      await deleteListItems(id, selectedItems);
      setItems(items.filter(item => !selectedItems.includes(item.id)));
      setSelectedItems([]);
    } catch (err) {
      console.error('Error deleting items:', err);
    }
  };

  const handleRemoveSharing = async (userId) => {
    try {
      await removeUserSharing(id, userId);
      setSharedUsers(sharedUsers.filter(user => user.id !== userId));
    } catch (err) {
      console.error("Error removing sharing: ", err);
    }
  };

  const deleteList = async () => {
    const confirmDelete = window.confirm('Do you really want to delete the list?');
    if (!confirmDelete) return;

    try {
      await deleteShoppingList(id);
      navigate('/');
    } catch (err) {
      console.error("Error deleting list: ", err);
    }
  };

  return (
    <div className="p-4 max-w-md mx-auto bg-white shadow-lg rounded-lg">
      {/* Back Button */}
      <button
        onClick={() => navigate('/')}
        className="flex items-center mb-4 text-gray-600 hover:text-blue-600"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H3m0 0h6m-6 0l4-4m-4 4l4 4" />
        </svg>
        Back
      </button>

      <h2 className="text-2xl font-bold mb-4 text-blue-600">{listName}</h2>

      <div className="flex mb-4">
        <input
          value={item}
          onChange={e => setItem(e.target.value)}
          placeholder="Add an item"
          onKeyDown={handleKeyPress}
          className="flex-grow border border-gray-300 rounded-l-md p-2"
        />
        <button
          onClick={addItem}
          className="bg-blue-500 text-white rounded-r-md p-2 hover:bg-blue-600"
        >
          Add
        </button>
      </div>

      {items.length > 0 ? (
        <ul className="list-disc pl-5 mb-4">
          {items.map((item) => (
            <li key={item.id} className="flex items-center mb-2">
              <input
                type="checkbox"
                checked={selectedItems.includes(item.id)}
                onChange={() => handleSelectItem(item.id)}
                className="mr-2"
              />
              <span>{item.name}</span>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500">No items found</p>
      )}

      {/* Separator line */}
      <hr className="my-4 border-gray-300" />

      {/* Shared users section */}
      <div>
        <h3 className="text-lg font-semibold mb-2 text-blue-600">Shared With</h3>
        {sharedUsers.length > 0 ? (
          <ul className="mb-4">
            {sharedUsers.map(user => (
              <li key={user.id} className="flex justify-between items-center mb-2">
                <span className="text-gray-700">{user.username}</span>
                <button
                  onClick={() => handleRemoveSharing(user.id)}
                  className="bg-red-500 text-white rounded-md p-1 text-sm hover:bg-red-600"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">This list is not shared with anyone</p>
        )}
      </div>

      {selectedItems.length > 0 && (
        <button
          onClick={handleDeleteSelectedItems}
          className="bg-red-500 text-white rounded-md p-2 mb-2 hover:bg-red-600"
        >
          Delete Selected Items
        </button>
      )}

      <ShareListButton listId={id} />

      <button
        onClick={deleteList}
        className="bg-red-500 text-white rounded-md p-2 hover:bg-red-600"
      >
        Delete List
      </button>
    </div>
  );

}

export default List;
