import axiosInstance from "../axiosConfig";


export const checkAuthStatus = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axiosInstance.get('/isAuthenticated', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });
        return response.data.isAuthenticated;
    } catch (error) {
        console.error("Authentication check failed:", error);
        return false;
    }
};


export const getShoppingLists = async () => {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get('/shopping-lists', {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};

export const getListItems = async (listId) => {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`/shopping-lists/${listId}/items`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};

export const addItemToList = async (listId, item) => {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.post(`/shopping-lists/${listId}`, { itemName: item }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};

export const deleteListItems = async (listId, items) => {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.delete(`/shopping-lists/${listId}/items`, {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: { items }
    });
    return response.data;
};

export const createShoppingList = async (listData) => {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.post('/shopping-lists', listData, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};

export const updateShoppingList = async (listId, updatedData) => {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.put(`/shopping-lists/${listId}`, updatedData, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};

export const deleteShoppingList = async (listId) => {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.delete(`/shopping-lists/${listId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};

export const getSharedLists = async (listId) => {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`/shared-lists`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};

export const shareShoppingList = async (listId, userId) => {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.post(`/shopping-lists/${listId}/share`,
        { shareWithUserId: userId },
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );
    return response.data;
};

export const searchUsers = async (searchTerm) => {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get('/searchUsers', {
        params: { q: searchTerm },
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};

export const getListSharedUsers = async (listId) => {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get(`/shopping-lists/${listId}/shared-users`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};

export const removeUserSharing = async (listId, userId) => {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.delete(`/shopping-lists/${listId}/remove-sharing`,
        {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: { userId }
        }
    );
    return response.data;
};