import React from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutButton = ({ onLogout }) => {
  const navigate = useNavigate(); // Hook to navigate programmatically

  const handleLogout = () => {
    localStorage.removeItem('token');
    const token = localStorage.getItem('token');

    if (onLogout) {
      onLogout();
    }

    navigate('/login');
  };

  return (
    <button
      onClick={handleLogout}
      className="bg-red-500 text-white rounded-md p-2 hover:bg-red-600 transition w-full"
    >
      Logout
    </button>
  );
};

export default LogoutButton;