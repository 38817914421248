import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/login', { username, password });
      const token = response.data.token; // Get the token from the response
      localStorage.removeItem('token');

      localStorage.setItem('token', token);

      navigate('/');
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  const handleRegister = async () => {
    navigate('/register');
  };

  return (
    <div className="flex flex-col items-center p-4 max-w-md mx-auto bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6">Login</h2>
      <form onSubmit={handleLogin} className="w-full">
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="border border-gray-300 rounded-md p-2 mb-4 w-full"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="border border-gray-300 rounded-md p-2 mb-4 w-full"
        />
        <button
          type="submit"
          className="bg-blue-500 text-white rounded-md p-2 w-full hover:bg-blue-600 w-full"
        >
          Login
        </button>
      </form>
      <button
        type="button"
        onClick={handleRegister}
        className="mt-4 text-blue-500 hover:underline w-full"
      >
        Register
      </button>
    </div>
  );
};

export default Login;
