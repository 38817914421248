import React, { useState, useEffect } from 'react';
import { getShoppingLists, createShoppingList, getSharedLists, checkAuthStatus } from '../api/shoppingList';
import { Link } from 'react-router-dom';
import LogoutButton from '../components/LogoutButton';

const Home = () => {
  const [lists, setLists] = useState([]);
  const [newListName, setNewListName] = useState('');
  const [sharedLists, setSharedLists] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      const authStatus = await checkAuthStatus();
      setIsAuthenticated(authStatus);

      if (authStatus) {
        await fetchLists();
        await fetchSharedLists();
      } else {
        setLists([]);
        setSharedLists([]);
      }

      setLoading(false);
    };

    initializeData();
  }, []);

  const fetchLists = async () => {
    try {
      const fetchedLists = await getShoppingLists();
      setLists(fetchedLists);
    } catch (error) {
      console.error('Error fetching lists:', error);
    }
  };

  const fetchSharedLists = async () => {
    try {
      const fetchedLists = await getSharedLists();
      setSharedLists(fetchedLists);
    } catch (error) {
      console.error('Error fetching shared lists:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleCreateList();
    }
  };

  const handleCreateList = async () => {
    if (!newListName) return;

    try {
      const newList = await createShoppingList({ name: newListName });
      setLists([...lists, newList]);
      setNewListName(''); // Reset input
    } catch (error) {
      console.error('Error creating list:', error);
    }
  };

  const handleLogout = () => {
    // Clear lists and shared lists on logout
    setLists([]);
    setSharedLists([]);
    setIsAuthenticated(false); // Update authentication status
    // Optionally, clear any tokens or session data (if applicable)
    // e.g., localStorage.removeItem('token');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
      <h1 className="text-3xl font-bold text-blue-600 mb-6">Your Shopping Lists</h1>

      {/* List Shopping Lists */}
      <ul className="w-full max-w-md bg-white shadow-md rounded-lg divide-y divide-gray-200 mb-6">
        {loading ? ( // Display loading state
          <li className="p-4 text-gray-500">Loading...</li>
        ) : (
          lists.map((list) => (
            <li key={list.id} className="p-4 hover:bg-blue-100">
              <Link to={`/list/${list.id}`} className="block p-1 text-blue-700 text-lg">
                {list.name}
              </Link>
            </li>
          ))
        )}
      </ul>

      {/* Create New List */}
      <div className="flex flex-col items-center w-full max-w-md">
        <input
          type="text"
          placeholder="New List Name"
          value={newListName}
          onChange={(e) => setNewListName(e.target.value)}
          onKeyDown={handleKeyPress}
          className="w-full p-2 border border-gray-300 rounded-md mb-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={handleCreateList}
          className="w-full bg-blue-600 text-white font-semibold py-2 rounded-md hover:bg-blue-700 transition duration-200"
        >
          Create List
        </button>
      </div>

      {/* Shared Lists Section */}
      <div className="mt-6 w-full max-w-md">
        <h3 className="text-xl font-semibold text-blue-600 mb-2">Shared Lists</h3>
        {sharedLists.length > 0 ? (
          <ul className="bg-white shadow-md rounded-lg divide-y divide-gray-200">
            {sharedLists.map((list) => (
              <li key={list.id} className="p-4 hover:bg-blue-100">
                <Link to={`/list/${list.id}`} className="flex justify-between items-center text-blue-700 text-lg">
                  <span>{list.name}</span>
                  <span className="text-gray-500 text-sm">{list.owner_username}</span>
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">No shared lists</p>
        )}
      </div>

      {/* Logout Button */}
      <div className="mt-6">
        <LogoutButton onLogout={handleLogout} />
      </div>
    </div>
  );
};

export default Home;
